var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"password_reset"}},[_c('v-main',[_c('v-container',{staticClass:"blue lighten-3",attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('div',{attrs:{"xs10":"","sm4":"","md3":""}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.save)}}},[_c('h1',{staticClass:"blue--text text--darken-3"},[_vm._v("Restablecer Credenciales")]),_c('ValidationProvider',{attrs:{"vid":"token","name":"Token","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Token","hint":"Ingresa el token que te enviamos por correo electrónico","required":"","error-messages":errors,"success":valid},model:{value:(_vm.token),callback:function ($$v) {_vm.token=$$v},expression:"token"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"email","name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","append-icon":" ","type":"email","label":"Email","required":"","error-messages":errors,"success":valid},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"password","name":"Clave","rules":"required|confirmed:password_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","type":"password","autocomplete":"new-password","name":"input-10-1","label":"Clave","counter":"","error-messages":errors,"success":valid},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"password_confirmation","name":"Repetir Clave"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","type":"password","name":"input-10-1","label":"Repetir Clave","counter":"","error-messages":errors,"success":valid},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}})]}}],null,true)}),_c('div',{staticClass:"mt-2",attrs:{"align-self-center":""}},[_c('v-btn',{attrs:{"type":"submit","block":"","color":"primary","dark":"","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return passes(_vm.save)}}},[_vm._v("Restablecer Credenciales")]),_c('v-btn',{attrs:{"type":"button","block":"","to":"/login","color":"secondary","text":"","disabled":_vm.loading}},[_vm._v("Cancelar")])],1)],1)]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }