<template>
  <v-app id="password_reset">
    <v-main>
      <v-container
        fluid
        fill-height
        class="blue lighten-3"
      >
        <v-layout
          align-center
          justify-center
        >
          <div
            xs10
            sm4
            md3
          >
            <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
              <v-form @submit.prevent="passes(save)">
                <h1 class="blue--text text--darken-3">Restablecer Credenciales</h1>

                <ValidationProvider vid="token" name="Token" rules="required" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="token"
                    label="Token"
                    hint="Ingresa el token que te enviamos por correo electrónico"
                    required
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider vid="email" name="Email" rules="required|email" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="email"
                    append-icon=" "
                    type="email"
                    label="Email"
                    required
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider vid="password" name="Clave" rules="required|confirmed:password_confirmation" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="password"
                    type="password"
                    autocomplete="new-password"
                    name="input-10-1"
                    label="Clave"
                    counter
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>

                <ValidationProvider vid="password_confirmation" name="Repetir Clave" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="password_confirmation"
                    type="password"
                    name="input-10-1"
                    label="Repetir Clave"
                    counter
                    :error-messages="errors"
                    :success="valid"
                    ></v-text-field>
                </ValidationProvider>

                <div align-self-center class="mt-2">
                  <v-btn type="submit" block @click.prevent="passes(save)" color="primary"
                    dark :loading="loading">Restablecer Credenciales</v-btn>
                  <v-btn type="button" block to="/login" color="secondary" text :disabled="loading">Cancelar</v-btn>
                </div>
              </v-form>
            </ValidationObserver>
          </div>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    loading: false,
    token: '',
    email: '',
    password: '',
    password_confirmation: '',
  }),
  methods: {
    save: function () {
      this.loading = true

      const data = {
        token: this.token,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
      }

      this.$http.post('password/reset', data)
      .then((response) => {
        this.$eventHub.$emit('snackbar-message', response.data.message, 'success')

        this.$refs.obs.reset();
        this.$router.push({name: 'login'}, () => {})
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          case 422:
            this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario')

            this.$refs.obs.setErrors(error.response.data.errors);

            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
        }
      })
      .then(() => {
        this.loading = false
      })
    },
  },

  created() {
    this.token = this.$route.params.token || '';
    this.email = this.$route.query._email || '';
  },
}
</script>

<style>
  #password_reset .success--text {
      color: #1B5E20 !important;
      caret-color: #1B5E20 !important;
  }

  #password_reset .error--text {
      color: #D32F2F !important;
      caret-color: #D32F2F !important;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 0px solid #212121;
    -webkit-text-fill-color: #212121;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;

  }
</style>
